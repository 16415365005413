body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.poster {
  min-width: 250px;
  margin: 0.2em;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  opacity: 1;
  transform: scale(1);
  -ms-transform: scale(1); /* IE 9 */
  -webkit-transform: scale(1); /* Safari and Chrome */
  position: relative;
  z-index: 500;
}
.poster:hover {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  opacity: 0.8;
  transform: scale(1.15);
  -ms-transform: scale(1.15); /* IE 9 */
  -webkit-transform: scale(1.15); /* Safari and Chrome */
  position: relative;
  z-index: 600;
}

.downloadLink {
  width: 50%;
  display: inline-block;
  text-align: center;
  background-color: #eee;
  border-right: 1px solid #fff;
  position: relative;
  top: -0.3em;
  padding: 0.2em;
  color: #000;
  text-decoration: none;
  box-sizing: border-box;
  text-transform: uppercase;
}

.posterGallery {
  width: calc(100% - 40px);
  height: 33%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
}

.promoInfo {
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  outline: currentcolor none 0px !important;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  padding: 8px;
  color: #808080;
}

.promoInfo input {
  border: none;
  border-bottom: 1px solid #000;
  color: #000;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
